import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0 20px;
  height: 60px;
  background-color: ${colors.white};
  position: fixed;
  left: 0;
  right: 0;
  /* box-shadow: 0px 1px 5px ${colors.darkCard}; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const Content = styled.div`
  padding: 80px 10%;
`;

export const ContainerSearch = styled.div`
  flex: 1;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 6px;
  background-color: ${colors.white};
  /* box-shadow: 0.2px 0.5px 7px ${colors.darkCard}; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const ContainerRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ContainerFlex = styled.div`
  flex: 1;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  background-color: ${colors.dark};
  color: ${colors.white};
`;

export const Tr = styled.tr`
  border: 1px solid #dddddd;
  background-color: ${(props) =>
    props.index % 2 == 0 ? colors.white : colors.grey}};
`;

export const Td = styled.td`
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  color: ${(props) => (props.color ? props.color : colors.black)}};
  ${(props) => props.bold && `font-weight: bold`};
`;
