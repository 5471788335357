import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.button`
  display: flex;
  height: 60px;
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : colors.primary)};
  align-self: stretch;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  cursor: pointer;
  border: none;
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white};
`;
