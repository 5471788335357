import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { AuthProvider } from './context/auth';

import Routes from './routes';

export default function App() {
  return (
    <AuthProvider>
      <Routes />
      <ToastContainer autoClose={3000} />
    </AuthProvider>
  );
}
