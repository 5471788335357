import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: ${(props) => (props.disabled ? colors.grey : colors.white)};
  padding: 5px;
  color: ${colors.black};
  align-self: stretch;
  border-radius: 6px;
  border: 2px solid ${colors.dark};
`;

export const Button = styled.button`
  display: flex;
  width: 40px;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: ${colors.white};
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${colors.black};
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: ${colors.white};
  height: 55px;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 20px;
  font-weight: bold;
  color: ${colors.black};

  //FIREFOX
  -moz-appearance: textfield;
  //Chrome, Safari, Edge, Opera
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Chrome, Safari, Edge, Opera
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox
  input[type=number] {
    -moz-appearance: textfield;
  } */
`;
