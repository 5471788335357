export const colors = {
  primary: '#619744',
  dark: '#424242',
  deepDark: '#212121',
  grey: '#DEDEDE',
  error: '#951d23',
  black: '#000000',
  white: '#ffffff',
  white70: 'rgba(255, 255, 255, 0.7)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white30: 'rgba(255, 255, 255, 0.3)',
  red: '#ff0000',
  yellow: '#F0DB0D',
  darkCard: '#4A4A4A',
  greyLight: '#f0f0f5',
};
