import { Container, Text } from './styles';

export default function Button({
  text,
  color,
  disabled = false,
  loading,
  onPress,
}) {
  return (
    <Container disabled={disabled} color={color} onClick={onPress}>
      {loading ? <Text>Loading...</Text> : <Text>{text}</Text>}
    </Container>
  );
}
