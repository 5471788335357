import React from 'react';
import { MdClose } from 'react-icons/md';

import { Container, Button, Label, Input } from './styles';
import { colors } from '../../commonStyle';

export default function InputComponent(
  {
    label,
    type = 'text',
    value,
    onChange,
    disabled = false,
    onPress,
    maxLength,
  },
  ...rest
) {
  return (
    <Container disabled={disabled}>
      {/* <Label>{label}</Label> */}
      <Input
        {...rest}
        type={type}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
      <Button onClick={onPress}>
        <MdClose size={40} color={colors.black} />
      </Button>
    </Container>
  );
}
