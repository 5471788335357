import React from 'react';
import {
  BrowserRouter,
  Routes as RoutesComponent,
  Route,
} from 'react-router-dom';

import Home from '../pages/Home';

export default function Routes() {
  return (
    <BrowserRouter>
      <RoutesComponent>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
      </RoutesComponent>
    </BrowserRouter>
  );
}
